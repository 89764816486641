import { Typography } from '@mui/material';
import React from 'react';

export const Subtitle = () => {
  return (
    <Typography component={'p'} textAlign={'justify'}>
      W CWM, oprócz Wsparcia Standardowego, oferujemy Państwa usługi <b>Wsparcia Premium</b> oraz{' '}
      <b>Wsparcia Premium Plus</b>, które przeznaczone są dla placówek potrzebujących{' '}
      <b>
        szybkości kontaktu, dłuższej dostępności wsparcia, indywidualnego opiekuna czy wykonania pewnych prac
        programistycznych.
      </b>
    </Typography>
  );
};
