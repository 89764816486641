import { ListItem, Typography } from '@mui/material';
import React from 'react';

interface GuideAreaListItemProps {
  value: string;
}

const GuideAreaListItem: React.FC<GuideAreaListItemProps> = ({ value }) => {
  return (
    <ListItem sx={{ display: 'list-item' }}>
      <Typography sx={{ color: 'primary.main' }} variant='subtitle1' component={'span'}>
        {value}
      </Typography>
    </ListItem>
  );
};

export default GuideAreaListItem;
