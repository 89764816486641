import React from 'react';
import PageSectionWithImage from '../../../components/Sections/PageSectionWithImage';
import { StaticImage } from 'gatsby-plugin-image';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Box, Typography } from '@mui/material';

export default () => {
  return (
    <PageSectionWithImage
      maxWidth='lg'
      color='white'
      bgimage={
        <StaticImage
          style={{ height: '100%' }}
          layout='fullWidth'
          quality={95}
          src='../../../images/wsparcie_hero.jpg'
          alt='Wsparcie'
        />
      }
    >
      <PageSectionTitle>Czym jest wsparcie w Mediporcie?</PageSectionTitle>
      <Box>
        <Typography component={'p'} gutterBottom>
          Wsparcie w Mediporcie ma różnorodny i złożony charakter.
        </Typography>
        <Typography component={'p'} gutterBottom>
          Nasza pomoc Klientom to zarówno opieka w ramach Centrum Wsparcia Mediporta czyli pomoc ekspertów technicznych
          jak i wspieranie samodzielnej obsługi naszej aplikacji poprzez przewodniki czy szkolenia użytkowników Systemu.
          Udostępniamy również możliwość skorzystania ze współpracy z naszymi Partnerami IT.
        </Typography>
        <Typography component={'p'} gutterBottom>
          Dodatkowo oferujemy usługi ułatwiające prowadzenie bieżącej działalności placówek m.in.. poprzez prowadzenie
          rozliczeń kontraktów z NFZ.
        </Typography>
      </Box>
    </PageSectionWithImage>
  );
};
