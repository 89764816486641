import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Typography } from '@mui/material';

export default () => {
  return (
    <PageSection bgcolor='tertiary.main' maxWidth='lg'>
      <PageSectionTitle>Wsparcie przez Partnerów IT</PageSectionTitle>

      <Typography variant='h6' component={'p'} sx={{ marginBottom: 3 }} textAlign={'justify'}>
        Jeśli zależy Państwu na wsparciu opartym całkowicie na indywidualnych warunkach czy kontakcie bezpośrednim z
        ekspertem technicznym, zapraszamy do skorzystania z usług jednego z naszych Partnerów IT.
      </Typography>
    </PageSection>
  );
};
