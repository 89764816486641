import { Card, CardContent, Box, Typography, CardActions } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';
import React from 'react';

interface TrainingCardProps {
  title: string;
  description: string;
  link: string;
  image: React.ReactNode;
}

export const TrainingCard: React.FC<TrainingCardProps> = ({ description, title, link, image }) => {
  return (
    <Card variant='outlined' sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {image}
      <CardContent sx={{ padding: 3, flex: 1 }}>
        <Box position='relative'>
          <Typography variant='h6' component={'h4'} textTransform='uppercase' gutterBottom>
            {title}
          </Typography>
          <Box position='absolute' top={0} bottom={0} ml={-3} width={7} bgcolor='divider' />
        </Box>
        <Typography variant='body2'>{description}</Typography>
      </CardContent>
      <CardActions sx={{ padding: 3, paddingTop: 0, justifyContent: 'space-around' }}>
        <Button variant='contained' sx={{ minWidth: '60%' }} href={link}>
          Sprawdź szczegóły
        </Button>
      </CardActions>
    </Card>
  );
};
