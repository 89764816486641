import { Package } from '../package';

type TableRow = {
  title: string;
  valueGetter: (p: Package) => unknown;
};

export const tableRows: TableRow[] = [
  {
    title: 'Forma kontaktu',
    valueGetter: (p) => p.contactForm
  },
  {
    title: 'Indywidualny opiekun',
    valueGetter: (p) => p.individualTutor
  },
  {
    title: 'Maksymalny czas oczekiwania na połączenie',
    valueGetter: (p) => p.callWaitingTime
  },
  {
    title: 'Rodzaje obsługiwanych zgłoszeń',
    valueGetter: (p) => p.typesOfHandledCalls
  },
  {
    title: 'Godziny funkcjonowaia',
    valueGetter: (p) => p.operationHours
  },
  {
    title: 'Godziny wsparcia w pakiecie',
    valueGetter: (p) => p.suppportHours
  },
  {
    title: 'Godziny prac programistycznych lub analitycznych w pakiecie',
    valueGetter: (p) => p.programmingAnalysisWorkHours
  },
  {
    title: 'Minimalny czas trwania usługi',
    valueGetter: (p) => p.minimalServiceDuration
  },
  {
    title: 'Opłata za godzinę Wsparcia poza pakietem',
    valueGetter: (p) => p.outOfPackageSupportHoursCharge
  },
  {
    title: 'Opłata bazowa',
    valueGetter: (p) => p.baseFee
  }
];
