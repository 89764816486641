import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Package } from './package';
import { Subtitle } from './Subtitle';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Button } from 'gatsby-material-ui-components';
import { PackagesTable } from './Table/PackagesTable';
import { PackagesTableMobileWrapper } from './Table/PackagesTableMobileWrapper';

interface CWMSupportKindsSectionProps {
  packages: Package[];
}

const CWMSupportKindsSection: React.FC<CWMSupportKindsSectionProps> = ({ packages }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <PageSection maxWidth='lg'>
      <PageSectionTitle>Rodzaje wsparcia w CWM</PageSectionTitle>
      <Subtitle />

      {isTablet ? <PackagesTableMobileWrapper packages={packages} /> : <PackagesTable packages={packages} />}

      <Typography variant='body1' marginBottom={3}>
        Jeśli jesteście Państwo zainteresowani wariantem Premium i Premium Plus, prosimy o wypełnienie formularza.
      </Typography>

      <Button to='#formularz-kontaktowy'>Kup Wsparcie Premium lub Premium Plus</Button>
    </PageSection>
  );
};

export default CWMSupportKindsSection;
