import React from 'react';
import StandardFrame from '../components/StandardFrame';
import SelfLearningSection from '../sections/wsparcie/self-lerning';
import CWMSection from '../sections/wsparcie/cwm';
import SettelementWithNFZSection from '../sections/wsparcie/rozliczenia_z_nfz';
import CWMSupportKindsSection from '../sections/wsparcie/cwm-rodzaje-wsparcia';
import { PageProps, graphql } from 'gatsby';
import ItPartnersSection from '../sections/wsparcie/partnerzy-it';
import Header from '../sections/wsparcie/naglowek';
import { ContactForm } from '../components/Sections/ContactForm';
import { Helmet } from 'react-helmet';

export const pageQuery = graphql`
  query Support {
    packages: allPakietyYaml {
      edges {
        node {
          id
          title
          contactForm
          individualTutor
          callWaitingTime
          typesOfHandledCalls
          operationHours
          suppportHours
          programmingAnalysisWorkHours
          minimalServiceDuration
          outOfPackageSupportHoursCharge
          baseFee
          isPromoted
        }
      }
    }
  }
`;

export default ({ data: { packages } }: PageProps<Queries.SupportQuery>) => {
  return (
    <>
      <Helmet title='Wsparcie' />
      <StandardFrame>
        <Header />
        <CWMSection />
        <CWMSupportKindsSection
          packages={packages.edges.map((e) => ({
            id: e.node.id!,
            baseFee: e.node.baseFee!,
            callWaitingTime: e.node.callWaitingTime!,
            contactForm: e.node.contactForm!,
            individualTutor: e.node.individualTutor!,
            minimalServiceDuration: e.node.minimalServiceDuration!,
            operationHours: e.node.operationHours!,
            outOfPackageSupportHoursCharge: e.node.outOfPackageSupportHoursCharge!,
            programmingAnalysisWorkHours: e.node.programmingAnalysisWorkHours!,
            suppportHours: e.node.suppportHours!,
            title: e.node.title!,
            typesOfHandledCalls: e.node.typesOfHandledCalls!,
            isPromoted: e.node.isPromoted!
          }))}
        />
        <ItPartnersSection />
        <SelfLearningSection />
        <SettelementWithNFZSection />
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
