import React, { useState } from 'react';
import { PackageTableProps, PackagesTable } from './PackagesTable';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { IconButton } from 'gatsby-material-ui-components';

type PackagesTableMobileWrapperProps = Omit<PackageTableProps, 'headerCell'>;

export const PackagesTableMobileWrapper: React.FC<PackagesTableMobileWrapperProps> = ({ packages }) => {
  const [currentPackageIndex, setCurrentPackageIndex] = useState(packages.indexOf(packages.find((p) => p.isPromoted)!));

  const currentPackage = packages[currentPackageIndex];

  const handlePrev = () => setCurrentPackageIndex((prev) => prev - 1);
  const handleNext = () => setCurrentPackageIndex((prev) => prev + 1);

  const isFirst = currentPackageIndex === 0;
  const isLast = currentPackageIndex === packages.length - 1;

  return (
    <PackagesTable
      packages={[currentPackage]}
      headerCellProps={{ sx: { paddingX: 2 } }}
      headerCell={
        <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
          <IconButton size='small' onClick={handlePrev} disabled={isFirst}>
            <ChevronLeft />
          </IconButton>
          <Typography variant='h5'>{currentPackage.title}</Typography>
          <IconButton size='small' onClick={handleNext} disabled={isLast}>
            <ChevronRight />
          </IconButton>
        </Stack>
      }
    />
  );
};
