import { Typography, Stack, useMediaQuery, useTheme } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { TrainingCard } from './TrainingCard';

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography variant='h3' gutterBottom marginTop={6}>
        Szkolenia on-line
      </Typography>
      <Typography marginBottom={4}>
        Jeśli jednak, potrzebujecie Państwo wsparcia, ale chcecie samodzielnie pracować w systemie, zachęcamy do
        skorzystania ze szkoleń on-line:
      </Typography>

      <Stack direction={isMobile ? 'column' : 'row'} spacing={10}>
        <TrainingCard
          description='Szkolenia użytkowe z programu przeznaczone są dla wszystkich pracowników placówek medycznych korzystających z systemu Mediporta, zarówno dla personelu medycznego jak i rejestracji oraz administratorów i osób zarządzających.'
          title='Szkolenia użytkowe'
          link='/szkolenia-uzytkowe'
          image={<StaticImage src='../../../../images/wsparcie_szkolenia_uzytkowe.jpg' alt='Szkolenia użytkowe' />}
        />
        <TrainingCard
          description='Usługa skierowana do osób zajmujących się sprawozdawczością oraz comiesięcznymi rozliczeniami placówki z Narodowym Funduszem Zdrowia w systemie Mediporta.	'
          title='Szkolenia z rozliczeń z NFZ'
          link='/szkolenia-z-rozliczen-nfz'
          image={
            <StaticImage
              src='../../../../images/wsparcie_szkolenia_z_rozliczen_nfz.jpg'
              alt='Szkolenia z rozliczeń z NFZ'
            />
          }
        />
      </Stack>
    </>
  );
};
