import { Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import GuideAreaList from './GuideAreaList';

export default () => {
  return (
    <>
      <Typography variant='h3' marginTop={3} gutterBottom>
        Przewodniki
      </Typography>
      <Typography>
        Aby wdrażanie oraz korzystanie z systemu Mediporta było jeszcze prostsze, stworzyliśmy dla Państwa zbiór
        Przewodników, które bardzo dokładnie opisują 6 głównych obszarów:
      </Typography>

      <GuideAreaList />

      <Typography marginBottom={1}>
        Dzięki wiedzy zawartej w Przewodnikach, spora część naszych Klientów, samodzielnie rozwiązuje bieżące kwestie
        związane z korzystaniem z systemu.
      </Typography>
      <StaticImage src='../../../../images/wsparcie_przewodniki.jpg' alt='Samodzielna obsługa systemu' />
    </>
  );
};
