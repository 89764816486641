import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import HowAboutSelfLerningSubSection from './HowAboutSelfLerningSubSection';
import GuidesSubSection from './guides';
import TrainingsSubSection from './trainings';

export default () => {
  return (
    <PageSection maxWidth='lg'>
      <HowAboutSelfLerningSubSection />
      <GuidesSubSection />
      <TrainingsSubSection />
    </PageSection>
  );
};
