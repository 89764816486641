import React from 'react';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Typography } from '@mui/material';

export default () => {
  return (
    <>
      <PageSectionTitle>A może samodzielna obsługa systemu?</PageSectionTitle>
      <Typography textAlign={'justify'} marginBottom={2}>
        Wierzymy, że efektywna praca w systemie to praca samodzielna, bez konieczności kontaktu z Centrum Wsparcia
        Mediporta. Dlatego zachęcamy do skorzystania z pomocy dostępnej bezpośrednio w aplikacji w formie Przewodników
        czy skorzystania ze szkoleń on-line zarówno użytkowych jak i z rozliczeń z NFZ.
      </Typography>
    </>
  );
};
