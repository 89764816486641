import { TableCell, TableRow, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';

export type PackageRowItem = {
  value: unknown;
  isPromoted: boolean;
};

interface PackagesTableRowProps {
  title: string;
  options: PackageRowItem[];
}

const getCellValue = (value: unknown) => {
  if (value === true) {
    return <CheckIcon sx={{ color: 'divider' }} />;
  }

  if (value === false || value === '-' || value === 0) {
    return <RemoveIcon color='disabled' />;
  }

  return value;
};

export const PackagesTableRow: React.FC<PackagesTableRowProps> = ({ options, title }) => {
  return (
    <TableRow>
      <TableCell component='th' scope='row' sx={{ borderBottomWidth: 2 }}>
        <Typography variant='subtitle2' component={'span'} fontWeight={600}>
          {title}
        </Typography>
      </TableCell>
      {options.map((option, index) => (
        <TableCell
          key={index}
          sx={{
            borderBottomWidth: 2,
            whiteSpace: 'pre-wrap',
            backgroundColor: option.isPromoted ? 'tertiary.transparent' : 'inherit'
          }}
        >
          <Typography variant='body2'>{getCellValue(option.value)}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};
