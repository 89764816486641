import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import { StaticImage } from 'gatsby-plugin-image';

const TelephoneItem = () => (
  <Stack direction={'row'} gap={1.5} alignItems={'center'}>
    <CallIcon sx={{ color: 'divider' }} fontSize='medium' />
    <Typography variant='h5' component={'span'} fontWeight={600}>
      +48 61 41 51 810
    </Typography>
  </Stack>
);

const EmailItem = () => (
  <Stack direction={'row'} gap={1.5} alignItems={'center'}>
    <MailIcon sx={{ color: 'divider' }} fontSize='medium' />
    <Typography variant='h5' component={'span'} fontWeight={600}>
      pomoc@mediporta.pl
    </Typography>
  </Stack>
);

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <PageSection bgcolor='tertiary.main' maxWidth='lg'>
      <PageSectionTitle>CWM czyli centrum wsparcia Mediporta</PageSectionTitle>
      <Typography textAlign={'justify'} marginBottom={2}>
        CWM to Zespół specjalistów technicznych, którzy obsługują Państwa w trakcie trwania naszej współpracy. Zajmują
        się głównie aspektami związanymi z wyjaśnianiem wątpliwości, wykonywaniem konfiguracji, czy odpowiadaniem na
        bieżące pytania związane z korzystaniem z Systemu. Monitorują również Państwa potrzeby, aby jeszcze lepiej
        dostosowywać System do Państwa potrzeb.
      </Typography>
      <Typography variant='h5' component={'p'} marginBottom={3}>
        Z CWM można kontaktować się następującymi kanałami:
      </Typography>
      <Stack gap={3}>
        <Stack gap={1}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <LanguageIcon sx={{ color: 'divider' }} fontSize='medium' />
            <Typography variant='h5' component={'span'} fontWeight={600}>
              Aplikacja Mediporta
            </Typography>
          </Stack>
          <StaticImage
            quality={100}
            src={'../../../images/centrum_wsparcia_mediporta.jpg'}
            alt={'Screenshot z aplikacji'}
          />
        </Stack>
        <Stack alignItems={isMobile ? 'flex-start' : 'center'} width={'100%'}>
          <Typography variant='h4' component={'span'} marginBottom={isMobile ? 1 : 0}>
            lub
          </Typography>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'center'}
            width={'100%'}
            gap={isMobile ? 1 : 12}
          >
            <Stack direction={'row'} flex={1} justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
              <TelephoneItem />
            </Stack>

            <Stack flex={1}>
              <EmailItem />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </PageSection>
  );
};
