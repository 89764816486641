import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableCellProps
} from '@mui/material';
import React from 'react';
import { PackagesTableRow } from './PackagesTableRow';
import { Package } from '../package';
import { tableRows } from './TableRows';

export interface PackageTableProps {
  packages: Package[];
  headerCell?: React.ReactNode;
  headerCellProps?: TableCellProps;
}

export const PackagesTable: React.FC<PackageTableProps> = ({ packages, headerCell, headerCellProps }) => {
  return (
    <TableContainer component={Paper} elevation={0} sx={{ marginY: 5 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottomWidth: 2, whiteSpace: 'pre' }} />
            {packages.map((p, index) => (
              <TableCell
                {...headerCellProps}
                key={index}
                sx={{
                  borderBottomWidth: 2,
                  whiteSpace: 'pre',
                  backgroundColor: p.isPromoted ? 'tertiary.transparent' : 'inherit',
                  borderTopRightRadius: p.isPromoted ? 10 : 0,
                  borderTopLeftRadius: p.isPromoted ? 10 : 0,
                  ...headerCellProps?.sx
                }}
              >
                {headerCell ? (
                  headerCell
                ) : (
                  <Typography variant='h5' component={'span'}>
                    {p.title}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <PackagesTableRow
              key={row.title}
              title={row.title}
              options={packages.map((p) => ({
                isPromoted: p.isPromoted,
                value: row.valueGetter(p)
              }))}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
