import { Box, List } from '@mui/material';
import React from 'react';
import ListItem from './GuideAreaListItem';

const items: string[] = [
  'Bezpieczeństwo',
  'Szybki start',
  'Użytkowanie systemu',
  'Prowadzenie rozliczeń z NFZ i komercyjnych',
  'Administrowanie placówką',
  'Konfiguracja systemu'
];

export default () => {
  return (
    <Box display={'flex'} justifyContent={'center'} marginBottom={2}>
      <List
        dense
        sx={{
          listStyle: 'decimal',
          listStylePosition: 'inside',
          columns: 2,
          minWidth: '55%'
        }}
      >
        {items.map((item, index) => (
          <ListItem key={index} value={item} />
        ))}
      </List>
    </Box>
  );
};
