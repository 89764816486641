import React from 'react';
import PageSection from '../../../components/Sections/PageSection';
import PageSectionTitle from '../../../components/Sections/PageSectionTitle';
import { Stack, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from 'gatsby-material-ui-components';

export default () => {
  return (
    <PageSection bgcolor='tertiary.main' maxWidth='lg'>
      <PageSectionTitle>Rozliczenia z NFZ</PageSectionTitle>
      <Stack gap={5}>
        <Typography>
          Aby jeszcze bardziej ułatwić Państwu funkcjonowanie i skupienie się na rozwoju i optymalnym prowadzeniu
          placówek, oferujemy możliwość całkowitego przejęcia od Placówek obowiązków sprawozdawczości do NFZ. Dzięki
          usłudze Rozliczeń z NFZ Państwa podmiot medyczny zyskuje kompletne, comiesięczne i terminowe rozliczenie z
          NFZ-em (np. w zakresie POZ, AOS oraz STM).
        </Typography>

        {/* Layout constraind nie działą poprawnie i rozszerza się ponad swoją szerokość, ograniczamy dając maxWidth równe szerokości zdjęcia */}
        <StaticImage
          src={'../../../images/rozliczenia_z_nfz.png'}
          alt={'Rozliczenia NFZ'}
          width={945}
          style={{ maxWidth: 945 }}
        />

        <Button to='#formularz-kontaktowy' sx={{ alignSelf: 'start' }}>
          Kup Rozliczenia z NFZ
        </Button>
      </Stack>
    </PageSection>
  );
};
